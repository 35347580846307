import { handleResponse, handleError } from "./apiUtils";
import { getIdpAccessToken } from "../sagas/idpSaga";
const baseUrl = process.env.REACT_APP_API_URL;

function getRealAccessToken() {
  const accessToken = getIdpAccessToken();
  if (accessToken) return accessToken;
}

export function getUserInfo() {
  const userInfoUrl = baseUrl + "/api/v1/userService/me";
  return fetch(userInfoUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserMenu() {
  const userInfoUrl = baseUrl + "/api/v1/module-service/menu-portal";
  return fetch(userInfoUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function changeUserPassword(user) {
  const internalProvidersUrl = baseUrl + "/api/v1/UserService/change-password";
  return fetch(internalProvidersUrl, {
    method: user.isUserVerified ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getIdpAccessToken(),
    },
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function resetUserPassword(userId) {
  const internalProvidersUrl =
    baseUrl + "/api/v1/UserService/reset-password/" + userId;
  return fetch(internalProvidersUrl, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getIdpAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function checkUsername(username) {
  const internalProvidersUrl =
    baseUrl + "/api/v1/UserService/check-username/" + username;
  return fetch(internalProvidersUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getIdpAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
